import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import BASE_URL from "../Components/auth/Baseurl";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
const SignIn = ({ setModalShow }) => {
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [activeTab, setActiveTab] = useState("login");
  const [showForgetPasswordForm, setShowForgetPasswordForm] = useState(false);

  // forget password//
  const toggleForgetPasswordForm = () => {
    setShowForgetPasswordForm(!showForgetPasswordForm);
  };

  const validationSchema3 = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit3 = async (values, { setSubmitting }) => {
    try {
      // Send email to the server for password reset
      const response = await axios.post(
        `${BASE_URL}/forgot-password`,
        { email: values.email }, // Include the email address in the request body
        {
          headers: {
            "Content-Type": "application/json", // Set the Content-Type header appropriately
          },
        }
      );
  
      console.log("Password reset email sent:", response);
  
      // Show success message or redirect to another page
    } catch (error) {
      console.error("Password reset email sending failed:", error);
      // Handle error, show error message, etc.
    } finally {
      setSubmitting(false);
    }
  };
  
  // End forget password//
  // Define initial values for both registration and become a host forms
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  const initialValues1 = {
    first_Name: "",
    last_Name: "",
    email1: "",
    password1: "",
  };

  // Define validation schema for both forms
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const validationSchema1 = Yup.object().shape({
    first_Name: Yup.string().required("First name is required"),
    last_Name: Yup.string().required("Last name is required"),
    email1: Yup.string().email("Invalid email").required("Email is required"),
    password1: Yup.string().required("Password is required"),
  });
  const validationSchema2 = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  // Handle image change for both forms
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleImageChange1 = (e) => {
    setImage1(e.target.files[0]);
  };

  // Handle form submission for registration
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("first_name", values.first_name);
      formData.append("role", "user");
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("image", image);

      const response = await axios.post(`${BASE_URL}/register`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      resetForm();
      setActiveTab("login");
      toast.success("Register successful");
      console.log(response, "res");
      // Handle successful registration here, such as redirecting the user to another page
    } catch (error) {
      toast.error("Register failed. Please check your credentials.");
      console.error("Registration failed:", error);
    } finally {
      setSubmitting(false);
    }
  };

  // Handle form submission for becoming a host
  const handleSubmit1 = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData1 = new FormData();
      formData1.append("first_name", values.first_Name);
      formData1.append("role", "host");
      formData1.append("last_name", values.last_Name);
      formData1.append("email", values.email1);
      formData1.append("password", values.password1);
      formData1.append("image", image1);

      const response = await axios.post(`${BASE_URL}/register`, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response, "res");
      resetForm();
      setActiveTab("login");
      toast.success("Become a host successful");
      // Handle successful registration here, such as redirecting the user to another page
    } catch (error) {
      toast.error("Become a hostfailed. Please check your credentials.");
      console.error("Become a host failed:", error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleSubmit2 = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData2 = new FormData();
      formData2.append("email", values.email);
      formData2.append("password", values.password);
      formData2.append("role", "host");

      // Make API request to login
      const response = await axios.post(`${BASE_URL}/login`, formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      resetForm();
      toast.success("Login successful");
      // Handle successful login
      console.log("Login successful:", response);

      // Reset form fields
      setSubmitting(false);
    } catch (error) {
      // Handle login error
      toast.error("Login failed. Please check your credentials.");
      console.error("Login failed:", error);

      // Optionally, you can set an error message for display
      // setErrorMsg("Invalid email or password");
    } finally {
      setSubmitting(false);
    }
  };
  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Handle modal close
  const Handleclose = () => {
    document.body.classList.remove("world");
    setModalShow(false);
  };
  return (
    <>
      <div  style={{ overflow:"scroll",height:"100vh" }} className="main-register-holder tabs-act">
        <div  style={{ padding: "20px",height:"100%"}} className="main-register fl-wrap">

          <span
            style={{
              display: "flex",
              justifyContent: "space-around",
              backgroundColor: "#4E65A3",
              padding: "20px",
              borderRadius: "30px",
            }}
          >
            <p
              style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }}
            >
              Welcome to FLY_inn.
            </p>
            <RxCross2
              onClick={Handleclose}
              style={{ color: "white", fontWeight: "50px" }}
              size={"20px"}
            />
          </span>
          <ul
            style={{ display: "flex", padding: "0px", width: "387px" }}
            className="tabs-menu fl-wrap no-list-style  "
          >
            <li className={activeTab === "login" ? "current" : ""}>
              <Link  to="#tab-1" onClick={() => handleTabChange("login")}>
                <i className="fal fa-sign-in-alt" /> Login
              </Link> 
            </li>
            <li className={activeTab === "register" ? "current" : ""}>
              <Link  to="#tab-2" onClick={() => handleTabChange("register")}>
                <i className="fal fa-user-plus" /> Register
              </Link> 
            </li>
            <li
              style={{ textAlign: "center" }}
              className={activeTab === "become" ? "current" : ""}
            >
              <Link  to="#tab-3" onClick={() => handleTabChange("become")}>
                <i className="fal fa-user-plus" /> Become a Host
              </Link> 
            </li>
          </ul>
          {/*tabs */}
          <div className="tabs-container">
            {/* Tab 1: Login */}
            <div
              id="tab-1"
              className={`tab-content ${
                activeTab === "login" ? "first-tab" : "hide"
              }`}
            >
              <div className="custom-form">
                {!showForgetPasswordForm && (
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema2}
                    onSubmit={handleSubmit2}
                  >
                    {({ isSubmitting }) => (
                      <Form name="loginform">
                        <label>
                          Username or Email Address <span>*</span>{" "}
                        </label>
                        <Field type="text" name="email" />
                        <ErrorMessage name="email" component="div" />

                        <label>
                          Password <span>*</span>{" "}
                        </label>
                        <Field type="password" name="password" />
                        <ErrorMessage name="password" component="div" />

                        <button
                          type="submit"
                          style={{ backgroundColor: "#4E65A3" }}
                          className="btn float-btn"
                          disabled={isSubmitting}
                        >
                          Log In <i className="fas fa-caret-right" />
                        </button>

                        <div className="clearfix" />
                        <div className="filter-tags">
                          <input id="check-a3" type="checkbox" name="check" />
                          <label htmlFor="check-a3">Remember me</label>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
                {!showForgetPasswordForm && (
                  <div className="lost_password">
                    <Link 
                      to="#"
                      className="show-lpt"
                      onClick={toggleForgetPasswordForm}
                    >
                      Forget Password
                    </Link> 
                  </div>
                )}
                {showForgetPasswordForm && (
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema3}
                    onSubmit={handleSubmit3}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <label>
                          Enter your Email Address to Reset Password{" "}
                          <span>*</span>{" "}
                        </label>
                        <Field type="text" name="email" />
                        <ErrorMessage name="email" component="div" />
                        <button
                          type="submit"
                          style={{ backgroundColor: "#4E65A3" }}
                          className="btn float-btn"
                          disabled={isSubmitting}
                        >
                          Reset Password <i className="fas fa-caret-right" />
                        </button>
                        <div className="lost_password">
                          <Link 
                            to="#"
                            className="show-lpt"
                            onClick={toggleForgetPasswordForm}
                          >
                            Cancel
                          </Link> 
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
            {/* Tab 2: Register */}
            <div
              className={`tab-content ${
                activeTab === "register" ? "first-tab" : "hide"
              }`}
              id="tab-2"
            >
              <div className="custom-form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form name="registerform">
                      <label>
                        First Name <span>*</span>{" "}
                      </label>
                      <Field type="text" name="first_name" />
                      <ErrorMessage name="first_name" component="div" />

                      <label>
                        Last Name <span>*</span>{" "}
                      </label>
                      <Field type="text" name="last_name" />
                      <ErrorMessage name="last_name" component="div" />

                      <label>
                        Email Address <span>*</span>{" "}
                      </label>
                      <Field type="text" name="email" />
                      <ErrorMessage name="email" component="div" />

                      <label>
                        Password <span>*</span>{" "}
                      </label>
                      <Field type="password" name="password" />
                      <ErrorMessage name="password" component="div" />

                      <label>
                        Image <span>*</span>{" "}
                      </label>
                      <input
                        type="file"
                        name="image"
                        onChange={handleImageChange}
                      />
                      <ErrorMessage name="image" component="div" />
                      <button
                        type="submit"
                        style={{ backgroundColor: "#4E65A3" }}
                        className="btn float-btn"
                        disabled={isSubmitting}
                      >
                        Register <i className="fas fa-caret-right" />
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            {/* Tab 3: Become a host */}
            <div
              className={`tab-content ${
                activeTab === "become" ? "first-tab" : "hide"
              }`}
              id="tab-3"
            >
              <div className="custom-form">
                <Formik
                  initialValues={initialValues1} // Change to initialValues1
                  validationSchema={validationSchema1} // Change to validationSchema1
                  onSubmit={handleSubmit1} // Change to handleSubmit1
                >
                  {({ isSubmitting }) => (
                    <Form name="becomeahost">
                      <label>
                        First Name <span>*</span>{" "}
                      </label>
                      <Field type="text" name="first_Name" />
                      <ErrorMessage name="first_Name" component="div" />

                      <label>
                        Last Name <span>*</span>{" "}
                      </label>
                      <Field type="text" name="last_Name" />
                      <ErrorMessage name="last_Name" component="div" />

                      <label>
                        Email Address <span>*</span>{" "}
                      </label>
                      <Field type="text" name="email1" />
                      <ErrorMessage name="email1" component="div" />

                      <label>
                        Password <span>*</span>{" "}
                      </label>
                      <Field type="password" name="password1" />
                      <ErrorMessage name="password1" component="div" />

                      <label>
                        Image <span>*</span>{" "}
                      </label>
                      <input
                        type="file"
                        name="image1"
                        onChange={handleImageChange1}
                      />
                      <ErrorMessage name="image1" component="div" />
                      <button
                        type="submit"
                        style={{ backgroundColor: "#4E65A3" }}
                        className="btn float-btn"
                        disabled={isSubmitting}
                      >
                        Become a host <i className="fas fa-caret-right" />
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            {/*tabs end */}
          </div>
          {/* Tab content end */}
          <div className="log-separator fl-wrap">
            <span>or</span>
          </div>
          <div className="soc-log fl-wrap">
            <p>For faster login or register use your social account.</p>
            <Link  to="#" className="facebook-log">
              {" "}
              Facebook
            </Link> 
          </div>
          </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignIn;
