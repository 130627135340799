import React from 'react'
import { Link } from 'react-router-dom'

function Bannerfour() {
  return (
<>
  <section className="parallax-section" data-scrollax-parent="true">
    <div
      className="bg par-elem "
      data-bg="images/bg/11.jpg"
      data-scrollax="properties: { translateY: '30%' }"
    />
    <div className="overlay op7" />
    {/*container*/}
    <div className="container">
      <div className="video_section-title fl-wrap">
        <h4>Aliquam erat volutpat interdum</h4>
        <h2>
          Get ready to start your exciting journey. <br /> Our agency will lead
          you through the amazing digital world
        </h2>
      </div>
      <Link  
        to="https://vimeo.com/70851162"
        className="promo-link big_prom   image-popup"
      >
        <i className="fal fa-play" />
        <span>Promo Video</span>
      </Link> 
    </div>
  </section>
  {/*section end*/}
  {/*section  */}
  <section data-scrollax-parent="true">
    <div className="container">
      <div className="section-title">
        <h2>How it works</h2>
        <div className="section-subtitle">Discover &amp; Connect </div>
        <span className="section-separator" />
        <p>
          Morbi varius, nulla sit amet rutrum elementum, est elit finibus
          tellus, ut tristique elit risus at metus.
        </p>
      </div>
      <div className="process-wrap fl-wrap">
        <ul className="no-list-style">
          <li>
            <div className="process-item">
              <span className="process-count">01 </span>
              <div className="time-line-icon">
                <i className="fal fa-map-marker-alt" />
              </div>
              <h4> Find Interesting Place</h4>
              <p>
                Proin dapibus nisl ornare diam varius tempus. Aenean a quam
                luctus, finibus tellus ut, convallis eros sollicitudin turpis.
              </p>
            </div>
            <span className="pr-dec" />
          </li>
          <li>
            <div className="process-item">
              <span className="process-count">02</span>
              <div className="time-line-icon">
                <i className="fal fa-mail-bulk" />
              </div>
              <h4> Contact a Few Owners</h4>
              <p>
                Faucibus ante, in porttitor tellus blandit et. Phasellus
                tincidunt metus lectus sollicitudin feugiat pharetra
                consectetur.
              </p>
            </div>
            <span className="pr-dec" />
          </li>
          <li>
            <div className="process-item">
              <span className="process-count">03</span>
              <div className="time-line-icon">
                <i className="fal fa-layer-plus" />
              </div>
              <h4> Make a Listing</h4>
              <p>
                Maecenas pulvinar, risus in facilisis dignissim, quam nisi
                hendrerit nulla, id vestibulum metus nullam viverra porta.
              </p>
            </div>
          </li>
        </ul>
        <div className="process-end">
          <i className="fal fa-check" />
        </div>
      </div>
    </div>
  </section>
  {/*section end*/}
  {/*section  */}
  <section className="gradient-bg hidden-section" data-scrollax-parent="true">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="colomn-text  pad-top-column-text fl-wrap">
            <div className="colomn-text-title">
              <h3>Our App Available Now</h3>
              <p>
                In ut odio libero, at vulputate urna. Nulla tristique mi a massa
                convallis cursus. Nulla eu mi magna. Etiam suscipit commodo
                gravida. Lorem ipsum dolor sit amet, conse ctetuer adipiscing
                elit.
              </p>
              <Link   to="#" className=" down-btn color3-bg">
                <i className="fab fa-apple" /> Apple Store{" "}
              </Link> 
              <Link   to="#" className=" down-btn color3-bg">
                <i className="fab fa-android" /> Google Play{" "}
              </Link> 
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="collage-image">
            <img src="images/api.png" className="main-collage-image" alt="" />
            <div className="images-collage-title color2-bg icdec">
              {" "}
              <img src="images/logo.png" alt="" />
            </div>
            <div
              className="images-collage_icon green-bg"
              style={{ right: "-20px", top: 120 }}
            >
              <i className="fal fa-thumbs-up" />
            </div>
            <div className="collage-image-min cim_1">
              <img src="images/api/1.jpg" alt="" />
            </div>
            <div className="collage-image-min cim_2">
              <img src="images/api/2.jpg" alt="" />
            </div>
            <div className="collage-image-btn green-bg">Booking now</div>
            <div className="collage-image-input">
              Search <i className="fa fa-search" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="gradient-bg-figure" style={{ right: "-30px", top: 10 }} />
    <div className="gradient-bg-figure" style={{ left: "-20px", bottom: 30 }} />
    <div
      className="circle-wrap"
      style={{ left: 270, top: 120 }}
      data-scrollax="properties: { translateY: '-200px' }"
    >
      <div className="circle_bg-bal circle_bg-bal_small" />
    </div>
    <div
      className="circle-wrap"
      style={{ right: 420, bottom: "-70px" }}
      data-scrollax="properties: { translateY: '150px' }"
    >
      <div className="circle_bg-bal circle_bg-bal_big" />
    </div>
    <div
      className="circle-wrap"
      style={{ left: 420, top: "-70px" }}
      data-scrollax="properties: { translateY: '100px' }"
    >
      <div className="circle_bg-bal circle_bg-bal_big" />
    </div>
    <div className="circle-wrap" style={{ left: "40%", bottom: "-70px" }}>
      <div className="circle_bg-bal circle_bg-bal_middle" />
    </div>
    <div className="circle-wrap" style={{ right: "40%", top: "-10px" }}>
      <div
        className="circle_bg-bal circle_bg-bal_versmall"
        data-scrollax="properties: { translateY: '-350px' }"
      />
    </div>
    <div className="circle-wrap" style={{ right: "55%", top: 90 }}>
      <div
        className="circle_bg-bal circle_bg-bal_versmall"
        data-scrollax="properties: { translateY: '-350px' }"
      />
    </div>
  </section>
  {/*section end*/}
  {/*section  */}

  
</>

  )
}

export default Bannerfour