// eslint-disable-next-line
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import React, { useState } from "react";

function ListenRight() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    roomType: "",
    listing_type: "",
    no_of_bedroom: "",
    no_of_guest: "",
    no_of_bed: "",
    no_of_bathroom: "",
    no_of_room: "",
    size: "",
    instant_booking: 0,
    unit_of_measure: "",
    addi_info: "",
    nightly: "",
    per: "",
    weekend: "",
    apply_weekend_price: "",
    weekly_seven_plus: "",
    weekly_thirty_plus: "",
    extra_service_prices: [],
    addi_guest: "",
    addi_guest_price: "",
    no_of_addi_guest: "",
    clean_fee: "",
    clean_fee_daily: "",
    clean_fee_per_stay: "",
    city_fee: "",
    city_fee_daily: "",
    city_fee_per_stay: "",
    security_deposit: "",
    tax: "",

    // Array to store extra service prices
    // Add other form fields here
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? (checked ? 1 : 0) : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    const Data = new FormData();
    Data.append("title", formData.title);
    Data.append("description", formData.description);
    Data.append("roomType", formData.roomType);
    Data.append("listing_type", formData.listing_type);
    Data.append("no_of_bedroom", formData.no_of_bedroom);
    Data.append("no_of_guest", formData.no_of_guest);
    Data.append("no_of_bed", formData.no_of_bed);
    Data.append("no_of_bathroom", formData.no_of_bathroom);
    Data.append("no_of_room", formData.no_of_room);
    Data.append("size", formData.size);
    Data.append("instant_booking", formData.instant_booking);
    Data.append("unit_of_measure", formData.unit_of_measure);
    Data.append("addi_info", formData.addi_info);
    Data.append("nightly", formData.nightly);
    Data.append("per", formData.per);
    Data.append("weekend", formData.weekend);
    Data.append("apply_weekend_price", formData.apply_weekend_price);
    Data.append("weekly_seven_plus", formData.weekly_seven_plus);
    Data.append("weekly_thirty_plus", formData.weekly_thirty_plus);
    Data.append(
      "extra_service_prices[0][name]",
      formData.extra_service_prices[0].name
    );
    Data.append(
      "extra_service_prices[0][price]",
      formData.extra_service_prices[0]?.price
    );
    Data.append(
      "extra_service_prices[0][type]",
      formData.extra_service_prices[0].type
    );

    // Create a new FormData object
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="col-md-9">
        <div className="dashboard-title   fl-wrap">
          <h3>Add Listing</h3>
        </div>

        <div className="profile-edit-container fl-wrap  block_box">
          <div className="custom-form add_room-item-wrap">
            <div className="add_room-container fl-wrap">
              {/*add_room-item end  */}
              <div className="add_room-item fl-wrap">
                <span
                  className="remove-rp tolt"
                  data-microtip-position="left"
                  data-tooltip="Remove"
                >
                  <i className="fal fa-times-circle" />
                </span>
                <div className="row">
                  <h3
                    style={{
                      textAlign: "start",
                      margin: "15px",
                      color: "#878C9F",
                    }}
                  >
                    What type of space would you like to list?*
                  </h3>
                  <div className="col-md-4">
                    <div
                      className="profile-edit-container fl-wrap block_box"
                      style={{ padding: "0px" }}
                    >
                      <div
                        className="custom-form"
                        style={{ paddingLeft: "20px", paddingTop: "10px" }}
                      >
                        {/* Checkboxes */}
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          <li>
                            <input
                              id="entirePlace"
                              type="radio"
                              name="roomType"
                              value="Entire Place"
                              checked={formData.roomType === "Entire Place"}
                              onChange={handleInputChange}
                            />
                            <label htmlFor="entirePlace">Entire Place</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="profile-edit-container fl-wrap block_box"
                      style={{ padding: "0px" }}
                    >
                      <div
                        className="custom-form"
                        style={{ paddingLeft: "20px", paddingTop: "10px" }}
                      >
                        {/* Checkboxes */}
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          <li>
                            <input
                              id="privateRoom"
                              type="radio"
                              name="roomType"
                              value="Private Room"
                              checked={formData.roomType === "Private Room"}
                              onChange={handleInputChange}
                            />
                            <label htmlFor="privateRoom">Private Room</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="profile-edit-container fl-wrap block_box"
                      style={{ padding: "0px" }}
                    >
                      <div
                        className="custom-form"
                        style={{ paddingLeft: "20px", paddingTop: "10px" }}
                      >
                        {/* Checkboxes */}
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          <input
                            id="sharedRoom"
                            type="radio"
                            name="roomType"
                            value="Shared Room"
                            checked={formData.roomType === "Shared Room"}
                            onChange={handleInputChange}
                          />
                          <label htmlFor="sharedRoom">Shared Room</label>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label>
                      Title
                      <i className="fal fa-briefcase" />
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      defaultValue=""
                      value={formData.title}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Description
                      <i className="fal fa-info-circle" />
                    </label>
                    <input
                      type="text"
                      placeholder="Description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Type of listing</label>
                    <div className="listsearch-input-item">
                      <select
                        value={formData.listing_type}
                        name="listing_type"
                        onChange={handleInputChange}
                        data-placeholder="Apartments"
                        className="chosen-select no-search-select"
                        style={{ display: "none" }}
                      >
                        <option>All Categories</option>
                        <option>Shops</option>
                        <option>Hotels</option>
                        <option>Restaurants</option>
                        <option>Fitness</option>
                        <option>Events</option>
                      </select>
                      <div
                        className="nice-select chosen-select no-search-select"
                        tabIndex={0}
                      >
                        <span className="current">Hotels</span>
                        <div className="nice-select-search-box">
                          <input
                            type="text"
                            className="nice-select-search"
                            placeholder="Search..."
                          />
                        </div>
                        <ul className="list">
                          <li data-value="All Categories" className="option">
                            All Categories
                          </li>
                          <li data-value="Shops" className="option">
                            Shops
                          </li>
                          <li
                            data-value="Hotels"
                            className="option selected focus"
                          >
                            Hotels
                          </li>
                          <li data-value="Restaurants" className="option">
                            Restaurants
                          </li>
                          <li data-value="Fitness" className="option">
                            Fitness
                          </li>
                          <li data-value="Events" className="option">
                            Events
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>
                      Number of Bedrooms
                      <i className="fal fa-bed" />
                    </label>
                    <input
                      type="text"
                      placeholder="Number of Bedrooms"
                      name="no_of_bedroom"
                      value={formData.no_of_bedroom}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Number of Guests
                      <i className="fal fa-user-friends" />
                    </label>
                    <input
                      type="text"
                      placeholder="Number of Guests"
                      name="no_of_guest"
                      value={formData.no_of_guest}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Number of Beds
                      <i className="fal fa-bed" />
                    </label>
                    <input
                      type="text"
                      placeholder="Number of Beds"
                      name="no_of_bed"
                      value={formData.no_of_bed}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Number of Bathrooms
                      <i className="fal fa-bath" />
                    </label>
                    <input
                      type="text"
                      placeholder="Number of Bathrooms"
                      name="no_of_bathroom"
                      value={formData.no_of_bathroom}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Number of Rooms
                      <i className="fal fa-door-open" />
                    </label>
                    <input
                      type="text"
                      placeholder="Number of Rooms"
                      name="no_of_room"
                      value={formData.no_of_room}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Size
                      <i className="fal fa-ruler" />
                    </label>
                    <input
                      type="text"
                      placeholder="Size"
                      name="size"
                      value={formData.size}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* hogya */}
                  <div className="col-md-12">
                    <div
                      className="profile-edit-container fl-wrap block_box"
                      style={{ padding: "0px" }}
                    >
                      <div
                        className="custom-form"
                        style={{ paddingLeft: "20px", paddingTop: "10px" }}
                      >
                        {/* Checkboxes */}
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          <li>
                            <input
                              id="check-aaa5"
                              type="checkbox"
                              name="instant_booking"
                              checked={formData.instant_booking === 1}
                              onChange={handleInputChange}
                            />
                            <label htmlFor="check-aaa5">
                              Allow instant booking for this space.
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label>
                      Unit of Measure
                      <i className="fal fa-ruler" />
                    </label>
                    <input
                      type="text"
                      placeholder="Unit of Measure"
                      defaultValue=""
                      name="unit_of_measure"
                      value={formData.unit_of_measure}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Additional Information
                      <i className="fal fa-info-circle" />
                    </label>
                    <input
                      type="text"
                      placeholder="Additional Information"
                      defaultValue=""
                      name="addi_info"
                      value={formData.addi_info}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-4">
                    <label>
                      Nightly Price
                      <i className="fal fa-moon-stars" />
                    </label>
                    <input
                      type="text"
                      placeholder="Nightly Price"
                      defaultValue=""
                      name="nightly"
                      value={formData.nightly}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Per
                      <i className="fal fa-calendar-alt" />
                    </label>
                    <input
                      type="text"
                      placeholder="Per"
                      defaultValue=""
                      name="per"
                      value={formData.per}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Select the days to apply weekend pricing.</label>
                    <div className="listsearch-input-item">
                      <select
                      value={formData.weekend}
                      onChange={handleInputChange}
                      name="weekend"
                        data-placeholder="Apartments"
                        className="chosen-select no-search-select"
                        style={{ display: "none" }}
                      >
                        <option>Saturday and Sunday</option>
                        <option>Monday and Tuesday</option>
                        <option>Wednesday and Thursday</option>
                      </select>
                      <div
                        className="nice-select chosen-select no-search-select"
                        tabIndex={0}
                      >
                        <span className="current">Saturday and Sunday</span>
                        <div className="nice-select-search-box">
                          <input
                            type="text"
                            className="nice-select-search"
                            placeholder="Search..."
                          />
                        </div>
                        <ul className="list">
                          <li data-value="All Categories" className="option">
                            All Days
                          </li>
                          <li data-value="Shops" className="option">
                          Monday and Tuesday
                          </li>
                          <li
                            data-value="Hotels"
                            className="option selected focus"
                          >
                            Wednesday and Thursday
                          </li>
                         
                          <li
                            data-value="Hotels"
                            className="option selected focus"
                          >
                            Saturday and Sunday
                          </li>
                         
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label>
                      Apply Weekend Price
                      <i className="fal fa-check-square" />
                    </label>
                    <input
                      type="text"
                      placeholder="Apply Weekend Price"
                      defaultValue=""
                      name="apply_weekend_price"
                      value={formData.apply_weekend_price}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Weekly (7+ Days) Price
                      <i className="fal fa-calendar-week" />
                    </label>
                    <input
                      type="text"
                      placeholder="Weekly (7+ Days) Price"
                      name="weekly_seven_plus"
                      value={formData.weekly_seven_plus}
                      onChange={handleInputChange}
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Weekly (30+ Days) Price
                      <i className="fal fa-calendar-alt" />
                    </label>
                    <input
                      type="text"
                      placeholder="Weekly (30+ Days) Price"
                      name="weekly_thirty_plus"
                      value={formData.weekly_thirty_plus}
                      onChange={handleInputChange}
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Extra Service Prices Name
                      <i className="fal fa-plus" />
                    </label>
                    <input
                      type="text"
                      placeholder="Extra Service Name"
                      defaultValue=""
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          extra_service_prices: [
                            {
                              ...prevFormData.extra_service_prices[0],
                              name: value,
                            },
                          ],
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Extra Service Prices Price
                      <i className="fal fa-plus" />
                    </label>
                    <input
                      type="text"
                      placeholder="Extra Service Prices"
                      defaultValue=""
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          extra_service_prices: [
                            {
                              ...prevFormData.extra_service_prices[0],
                              price: value,
                            },
                          ],
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Extra Service Prices Type
                      <i className="fal fa-plus" />
                    </label>
                    <input
                      type="text"
                      placeholder="Extra Service Type"
                      defaultValue=""
                      onChange={(e) => {
                        const value = e.target.value;
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          extra_service_prices: [
                            {
                              ...prevFormData.extra_service_prices[0],
                              type: value,
                            },
                          ],
                        }));
                      }}
                    />
                  </div>

                  {/* <div className="col-md-4">
                    <label>
                      Add More
                      <i className="fal fa-plus" />
                    </label>
                    <input
                      type="button"
                      placeholder="Additional Guest"
                      defaultValue=""
                      value={'Add More'}
                    />
                  </div> */}

                  {/* hogya */}
                  <div className="col-md-4">
                    <label>
                      Additional Guest
                      <i className="fal fa-user-plus" />
                    </label>
                    <input
                      type="text"
                      placeholder="Additional Guest"
                      name="addi_guest"
                      value={formData.addi_guest}
                      onChange={handleInputChange}
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Additional Guest Price
                      <i className="fal fa-dollar-sign" />
                    </label>
                    <input
                      type="text"
                      placeholder="Additional Guest Price"
                      name="addi_guest_price"
                      value={formData.addi_guest_price}
                      onChange={handleInputChange}
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Number of Additional Guests
                      <i className="fal fa-users" />
                    </label>
                    <input
                      type="text"
                      placeholder="Number of Additional Guests"
                      defaultValue=""
                      name="no_of_addi_guest"
                      value={formData.no_of_addi_guest}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Cleaning Fee
                      <i className="fal fa-broom" />
                    </label>
                    <input
                      type="text"
                      placeholder="Cleaning Fee"
                      name="clean_fee"
                      value={formData.clean_fee}
                      onChange={handleInputChange}
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Daily Cleaning Fee
                      <i className="fal fa-sun" />
                    </label>
                    <input
                      type="text"
                      placeholder="Daily Cleaning Fee"
                      defaultValue=""
                      name="clean_fee_daily"
                      value={formData.clean_fee_daily}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Cleaning Fee Per Stay
                      <i className="fal fa-suitcase" />
                    </label>
                    <input
                      type="text"
                      placeholder="Cleaning Fee Per Stay"
                      name="clean_fee_per_stay"
                      defaultValue=""
                      value={formData.clean_fee_per_stay}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      City Fee
                      <i className="fal fa-city" />
                    </label>
                    <input
                      type="text"
                      placeholder="City Fee"
                      defaultValue=""
                      name="city_fee"
                      value={formData.city_fee}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Daily City Fee
                      <i className="fal fa-building" />
                    </label>
                    <input
                      type="text"
                      placeholder="Daily City Fee"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      City Fee Per Stay
                      <i className="fal fa-hotel" />
                    </label>
                    <input
                      type="text"
                      placeholder="City Fee Per Stay"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Security Deposit
                      <i className="fal fa-shield-alt" />
                    </label>
                    <input
                      type="text"
                      placeholder="Security Deposit"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Tax
                      <i className="fal fa-percent" />
                    </label>
                    <input type="text" placeholder="Tax" defaultValue="" />
                  </div>
                  {/* hogya  */}
                  <div className="col-md-4">
                    <label>
                      Amenities
                      <i className="fal fa-couch" />
                    </label>
                    <input
                      type="text"
                      placeholder="Amenities"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Facilities
                      <i className="fal fa-hospital" />
                    </label>
                    <input
                      type="text"
                      placeholder="Facilities"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Address
                      <i className="fal fa-map-marker-alt" />
                    </label>
                    <input type="text" placeholder="Address" defaultValue="" />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Apartment/Suite
                      <i className="fal fa-building" />
                    </label>
                    <input
                      type="text"
                      placeholder="Apartment/Suite"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      City
                      <i className="fal fa-city" />
                    </label>
                    <input type="text" placeholder="City" defaultValue="" />
                  </div>
                  <div className="col-md-4">
                    <label>
                      State
                      <i className="fal fa-map" />
                    </label>
                    <input type="text" placeholder="State" defaultValue="" />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Zipcode
                      <i className="fal fa-mail-bulk" />
                    </label>
                    <input type="text" placeholder="Zipcode" defaultValue="" />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Area
                      <i className="fal fa-map-marked-alt" />
                    </label>
                    <input type="text" placeholder="Area" defaultValue="" />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Country
                      <i className="fal fa-globe" />
                    </label>
                    <input type="text" placeholder="Country" defaultValue="" />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Bedrooms
                      <i className="fal fa-bed" />
                    </label>
                    <input type="text" placeholder="Bedrooms" defaultValue="" />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Cancellation Policy
                      <i className="fal fa-times-circle" />
                    </label>
                    <input
                      type="text"
                      placeholder="Cancellation Policy"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Minimum Day Booking
                      <i className="fal fa-calendar-minus" />
                    </label>
                    <input
                      type="text"
                      placeholder="Minimum Day Booking"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Maximum Day Booking
                      <i className="fal fa-calendar-plus" />
                    </label>
                    <input
                      type="text"
                      placeholder="Maximum Day Booking"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Check-in After
                      <i className="fal fa-clock" />
                    </label>
                    <input
                      type="text"
                      placeholder="Check-in After"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Check-in Before
                      <i className="fal fa-clock" />
                    </label>
                    <input
                      type="text"
                      placeholder="Check-in Before"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Smoking Allowed
                      <i className="fal fa-smoking" />
                    </label>
                    <input
                      type="text"
                      placeholder="Smoking Allowed"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Pet Allowed
                      <i className="fal fa-paw" />
                    </label>
                    <input
                      type="text"
                      placeholder="Pet Allowed"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Party Allowed
                      <i className="fal fa-glass-cheers" />
                    </label>
                    <input
                      type="text"
                      placeholder="Party Allowed"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      Children Allowed
                      <i className="fal fa-child" />
                    </label>
                    <input
                      type="text"
                      placeholder="Children Allowed"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-md-4">
                    <label>
                      House Rules Additional Information
                      <i className="fal fa-info-circle" />
                    </label>
                    <input
                      type="text"
                      placeholder="House Rules Additional Information"
                      defaultValue=""
                    />
                  </div>

                  <div className="col-md-8">
                    <label>Item Details</label>
                    <textarea
                      cols={40}
                      rows={3}
                      placeholder="Datails"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Item Image</label>
                    <div className="add-list-media-wrap">
                      <div className="listsearch-input-item fl-wrap">
                        <div className="fuzone">
                          <form>
                            <div className="fu-text">
                              <span>
                                <i className="fal fa-images" /> Click here or
                                drop files to upload
                              </span>
                              <div className="photoUpload-files fl-wrap" />
                            </div>
                            <input type="file" className="upload" />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*add_room-item end  */}
            </div>
            {/* <Link  href="#" className="add-room-item">
            Add New <i className="fal fa-plus" />{" "}
          </Link>  */}
            <button className="logout_btn color2-bg" type="submit">
              Add <i className="fas fa-sign-out" />
            </button>
          </div>
        </div>
        <div className="dashboard-title  dt-inbox fl-wrap">
          <h3>Facilities</h3>
        </div>
        <div className="profile-edit-container fl-wrap block_box">
          <div className="custom-form">
            {/* Checkboxes */}
            <ul className="fl-wrap filter-tags no-list-style ds-tg">
              <li>
                <input
                  id="check-aaa5"
                  type="checkbox"
                  name="check"
                  defaultChecked=""
                />
                <label htmlFor="check-aaa5">Free WiFi</label>
              </li>
              <li>
                <input
                  id="check-bb5"
                  type="checkbox"
                  name="check"
                  defaultChecked=""
                />
                <label htmlFor="check-bb5">Parking</label>
              </li>
              <li>
                <input id="check-dd5" type="checkbox" name="check" />
                <label htmlFor="check-dd5">Fitness Center</label>
              </li>
              <li>
                <input id="check-cc5" type="checkbox" name="check" />
                <label htmlFor="check-cc5">Non-smoking Rooms</label>
              </li>
              <li>
                <input
                  id="check-ff5"
                  type="checkbox"
                  name="check"
                  defaultChecked=""
                />
                <label htmlFor="check-ff5">Airport Shuttle</label>
              </li>
              <li>
                <input id="check-c4" type="checkbox" name="check" />
                <label htmlFor="check-c4">Air Conditioning</label>
              </li>
            </ul>
            {/* Checkboxes end */}
          </div>
        </div>
      </div>
    </form>
  );
}

export default ListenRight;
