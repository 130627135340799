import React from 'react'
import { Link } from 'react-router-dom'

function BokkingR() {
  return (
    <div className="col-md-9">
    <div className="dashboard-title   fl-wrap">
      <h3>Bookings</h3>
    </div>
    {/* profile-edit-container*/}
    <div className="profile-edit-container fl-wrap block_box">
      {/* booking-list*/}
      <div className="booking-list">
        <div className="booking-list-message">
          <div className="booking-list-contr">
            <Link 
              to="#"
              className="green-bg tolt"
              data-microtip-position="left"
              data-tooltip="Approve"
            >
              <i className="fal fa-check" />
            </Link> 
            <Link 
              to="#"
              className="color-bg tolt"
              data-microtip-position="left"
              data-tooltip="Cancel"
            >
              <i className="fal fa-trash" />
            </Link> 
          </div>
          <div className="booking-list-message-avatar">
            <img src="images/avatar/3.jpg" alt="" />
          </div>
          <span className="booking-list-new green-bg">New</span>
          <div className="booking-list-message-text">
            <h4>
              Andy Smith - <span>27 December 2019</span>
            </h4>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Listing Item :</span> :
              <span className="booking-text">
                <Link  to="listing-sinle.html">Premium Plaza Hotel</Link> 
              </span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Persons :</span>
              <span className="booking-text">4 Peoples</span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Booking Date :</span>
              <span className="booking-text">02.03.2019 - 10.03.2019</span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Mail :</span>
              <span className="booking-text">
                <Link  to="#" target="_top">
                  yormail@domain.com
                </Link> 
              </span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Phone :</span>
              <span className="booking-text">
                <Link  to="tel:+496170961709" target="_top">
                  +496170961709
                </Link> 
              </span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Payment State :</span>
              <span className="booking-text">
                {" "}
                <strong className="done-paid">Paid</strong> using Paypal
              </span>
            </div>
            <span className="fw-separator" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              posuere convallis purus non cursus. Cras metus neque, gravida
              sodales massa ut.{" "}
            </p>
          </div>
        </div>
      </div>
      {/* dashboard-list end*/}
    </div>
    {/* profile-edit-container end*/}
    {/* profile-edit-container*/}
    <div className="profile-edit-container fl-wrap block_box">
      {/* booking-list*/}
      <div className="booking-list">
        <div className="booking-list-message">
          <div className="booking-list-contr">
            <Link 
              to="#"
              className="green-bg tolt"
              data-microtip-position="left"
              data-tooltip="Approve"
            >
              <i className="fal fa-check" />
            </Link> 
            <Link 
              to="#"
              className="color-bg tolt"
              data-microtip-position="left"
              data-tooltip="Cancel"
            >
              <i className="fal fa-trash" />
            </Link> 
          </div>
          <div className="booking-list-message-avatar">
            <img src="images/avatar/2.jpg" alt="" />
          </div>
          <div className="booking-list-message-text">
            <h4>
              Adam Forser - <span>17 october 2018</span>
            </h4>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Listing Item :</span> :
              <span className="booking-text">
                <Link  to="listing-sinle.html">Luxary Resaturant</Link> 
              </span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Persons :</span>
              <span className="booking-text">2 Peoples</span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Booking Date :</span>
              <span className="booking-text"> 10.03.2019</span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Mail :</span>
              <span className="booking-text">
                <Link  to="#" target="_top">
                  yormail@domain.com
                </Link> 
              </span>
            </div>
            <div className="booking-details fl-wrap">
              <span className="booking-title">Phone :</span>
              <span className="booking-text">
                <Link  to="tel:+496170961709" target="_top">
                  +496170961709
                </Link> 
              </span>
            </div>
            <span className="fw-separator" />
            <p>
              {" "}
              Nunc posuere convallis purus non cursus. Cras metus neque, gravida
              sodales massa ut.{" "}
            </p>
          </div>
        </div>
      </div>
      {/* dashboard-list end*/}
    </div>
    {/* profile-edit-container end*/}
    <div className="pagination">
      <Link  to="#" className="prevposts-link">
        <i className="fas fa-caret-left" />
        <span>Prev</span>
      </Link> 
      <Link  to="#">1</Link> 
      <Link  to="#" className="current-page">
        2
      </Link> 
      <Link  to="#">3</Link> 
      <Link  to="#">...</Link> 
      <Link  to="#">7</Link> 
      <Link  to="#" className="nextposts-link">
        <span>Next</span>
        <i className="fas fa-caret-right" />
      </Link> 
    </div>
  </div>
  
  )
}

export default BokkingR