import React from 'react'

function ProfileRight() {
  return (
    <div className="col-md-9">
    <div className="dashboard-title fl-wrap">
      <h3>Your Profile</h3>
    </div>
    {/* profile-edit-container*/}
    <div className="profile-edit-container fl-wrap block_box">
      <div className="custom-form">
        <div className="row">
          <div className="col-sm-6">
            <label>
              First Name <i className="fal fa-user" />
            </label>
            <input type="text" placeholder="Jessie" defaultValue="" />
          </div>
          <div className="col-sm-6">
            <label>
              Second Name <i className="fal fa-user" />
            </label>
            <input type="text" placeholder="Manrty" defaultValue="" />
          </div>
          <div className="col-sm-6">
            <label>
              Email Address
              <i className="far fa-envelope" />
            </label>
            <input
              type="text"
              placeholder="JessieManrty@domain.com"
              defaultValue=""
            />
          </div>
          <div className="col-sm-6">
            <label>
              Phone
              <i className="far fa-phone" />
            </label>
            <input
              type="text"
              placeholder="+7(123)987654"
              defaultValue=""
            />
          </div>
          <div className="col-sm-6">
            <label>
              {" "}
              Adress <i className="fas fa-map-marker" />
            </label>
            <input
              type="text"
              placeholder="USA 27TH Brooklyn NY"
              defaultValue=""
            />
          </div>
          <div className="col-sm-6">
            <label>
              {" "}
              Website <i className="far fa-globe" />
            </label>
            <input
              type="text"
              placeholder="themeforest.net"
              defaultValue=""
            />
          </div>
        </div>
        <label> Notes</label>
        <textarea
          cols={40}
          rows={3}
          placeholder="About Me"
          style={{ marginBottom: 20 }}
          defaultValue={""}
        />
        <div className="clearfix" />
        <label>Change Avatar</label>
        <div className="clearfix" />
        <div className="listsearch-input-item fl-wrap">
          <div className="fuzone">
            <form>
              <div className="fu-text">
                <span>
                  <i className="fal fa-images" /> Click here or drop files
                  to upload
                </span>
                <div className="photoUpload-files fl-wrap" />
              </div>
              <input type="file" className="upload" multiple="" />
            </form>
          </div>
        </div>
      </div>
    </div>
    {/* profile-edit-container end*/}
    <div className="dashboard-title dt-inbox fl-wrap">
      <h3>Your Socials</h3>
    </div>
    {/* profile-edit-container*/}
    <div className="profile-edit-container fl-wrap block_box">
      <div className="custom-form">
        <label>
          Facebook <i className="fab fa-facebook" />
        </label>
        <input
          type="text"
          placeholder="https://www.facebook.com/"
          defaultValue=""
        />
        <label>
          Twitter
          <i className="fab fa-twitter" />
        </label>
        <input
          type="text"
          placeholder="https://twitter.com/"
          defaultValue=""
        />
        <label>
          Vkontakte
          <i className="fab fa-vk" />
        </label>
        <input type="text" placeholder="https://vk.com" defaultValue="" />
        <label>
          {" "}
          Instagram <i className="fab fa-instagram" />
        </label>
        <input
          type="text"
          placeholder="https://www.instagram.com/"
          defaultValue=""
        />
        <button className="btn    color2-bg  float-btn">
          Save Changes
          <i className="fal fa-save" />
        </button>
      </div>
    </div>
    {/* profile-edit-container end*/}
  </div>
  )
}

export default ProfileRight