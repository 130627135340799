import React from 'react'
import { Link } from 'react-router-dom'

function Sidebar() {
  return (
    <div className="col-md-3">
    <div className="mob-nav-content-btn color2-bg init-dsmen fl-wrap">
      <i className="fal fa-bars" /> Dashboard menu
    </div>
    <div className="clearfix" />
    <div className="fixed-bar fl-wrap" id="dash_menu">
      <div className="user-profile-menu-wrap fl-wrap block_box">
        {/* user-profile-menu*/}
        <div className="user-profile-menu">
          <h3>Main</h3>
          <ul className="no-list-style">
            <li>
              <Link  to="dashboard.html">
                <i className="fal fa-chart-line" />
                Dashboard
              </Link> 
            </li>
            <li>
              <Link  to="dashboard-feed.html">
                <i className="fal fa-rss" />
                Your Feed <span>7</span>
              </Link> 
            </li>
            <li>
              <Link  to={'/profile'}>
                <i className="fal fa-user-edit" /> Edit profile
              </Link>
            </li>
            <li>
              <Link  to="dashboard-messages.html">
                <i className="fal fa-envelope" /> Messages <span>3</span>
              </Link> 
            </li>
            <li>
              <Link  to={'/changepassword'}>
                <i className="fal fa-key" />
                Change Password
              </Link>
            </li>
            <li>
              <Link  to="#" className="submenu-link">
                <i className="fal fa-plus" />
                Submenu
              </Link> 
              <ul className="no-list-style">
                <li>
                  <Link  to="#">
                    <i className="fal fa-th-list" /> Submenu 2{" "}
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    {" "}
                    <i className="fal fa-calendar-check" /> Submenu 2
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="fal fa-comments-alt" />
                    Submenu 2
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="fal fa-file-plus" /> Submenu 2
                  </Link> 
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {/* user-profile-menu end*/}
        {/* user-profile-menu*/}
        <div className="user-profile-menu">
          <h3>Listings</h3>
          <ul className="no-list-style">
            <li>
              <Link  to="dashboard-listing-table.html">
                <i className="fal fa-th-list" /> My listigs
              </Link> 
            </li>
            <li>
              <Link  to={'/booking'}>
                {" "}
                <i className="fal fa-calendar-check" /> Bookings{" "}
                <span>2</span>
              </Link>
            </li>
            <li>
              <Link  to="dashboard-review.html">
                <i className="fal fa-comments-alt" /> Reviews{" "}
              </Link> 
            </li>
            <li>
              <Link 
                to="dashboard-add-listing.html"
                className="user-profile-act"
              >
                <i className="fal fa-file-plus" /> Add New
              </Link> 
            </li>
            <li>
              <Link  to="#" className="submenu-link">
                <i className="fal fa-plus" />
                Submenu
              </Link> 
              <ul className="no-list-style">
                <li>
                  <Link  to="#">
                    <i className="fal fa-th-list" /> Submenu 2{" "}
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    {" "}
                    <i className="fal fa-calendar-check" /> Submenu 2
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="fal fa-comments-alt" />
                    Submenu 2
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="fal fa-file-plus" /> Submenu 2
                  </Link> 
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {/* user-profile-menu end*/}
        <button className="logout_btn color2-bg">
          Log Out <i className="fas fa-sign-out" />
        </button>
      </div>
    </div>
    <Link 
      className="back-tofilters color2-bg custom-scroll-link fl-wrap"
      to="#dash_menu"
    >
      Back to Menu
      <i className="fas fa-caret-up" />
    </Link> 
    <div className="clearfix" />
  </div>
  )
}

export default Sidebar