import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Listen from "./Pages/Listen";
import Booking from "./Pages/Booking";
import Profile from "./Pages/Profile";
import Changepassword from "./Pages/Changepassword";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/listen" element={<Listen />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/booking" element={<Booking />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/changepassword" element={<Changepassword />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;