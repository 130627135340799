import React from 'react'
import SignIn from '../Pages/SignIn';
import { Link } from 'react-router-dom';

function Header() {
  const [modalShow, setModalShow] = React.useState(false);
  const Handle = ()=>{
    setModalShow(true);
    document.body.classList.add("world");
  }
  
  return (
    <>
    
    
    <header className="main-header">
  {/* logo*/}
  <Link className="logo-holder " to={'/'}>
    <img style={{width:"70px",height:"65px",paddingBottom:"15px"}}  src="image/logo.png" alt="" />
  </Link>
  {/* logo end*/}
  {/* header-search_btn*/}
  <div className="header-search_btn show-search-button">
    <i className="fal fa-search" />
    <span>Search</span>
  </div>
  {/* header-search_btn end*/}
  {/* header opt */}
  <Link to="/listen" className="add-list color-bg">
    Add Listing{" "}
    <span>
      <i className="fal fa-layer-plus" />
    </span>
  </Link>
  <div
    className="cart-btn   show-header-modal"
    data-microtip-position="bottom"
    role="tooltip"
    aria-label="Your Wishlist"
  >
    <i className="fal fa-heart" />
    <span className="cart-counter green-bg" />{" "}
  </div>
  <div onClick={Handle}
    className="show-reg-form modal-open avatar-img"
    data-srcav="images/avatar/3.jpg"
  >
    <i className="fal fa-user" />
    Sign In
  </div>
  
  {/* header opt end*/}
  {/* lang-wrap*/}
  <div className="lang-wrap">
    <div className="show-lang">
      <span>
        <i className="fal fa-globe-europe" />
        <strong>En</strong>
      </span>
      <i className="fa fa-caret-down arrlan" />
    </div>
    <ul className="lang-tooltip lang-action no-list-style">
      <li>
        <Link  to="#" className="current-lan" data-lantext="En">
          English
        </Link> 
      </li>
      <li>
        <Link  to="#" data-lantext="Fr">
          Franais
        </Link> 
      </li>
      <li>
        <Link  to="#" data-lantext="Es">
          Espaol
        </Link> 
      </li>
      <li>
        <Link  to="#" data-lantext="De">
          Deutsch
        </Link> 
      </li>
    </ul>
  </div>
  {/* lang-wrap end*/}
  {/* nav-button-wrap*/}
  <div className="nav-button-wrap color-bg">
    <div className="nav-button">
      <span />
      <span />
      <span />
    </div>
  </div>
  {/* nav-button-wrap end*/}
  {/*  navigation */}
  <div className="nav-holder main-menu">
    <nav>
      <ul className="no-list-style">
        <li>
          <Link to="/" className="act-link">
            Home 
          </Link>
          {/*second level */}
         
          {/*second level end*/}
        </li>
        <li>
          <Link to="/listen">
            Listings 
            {/* <i className="fa fa-caret-down" /> */}
          </Link>
          {/*second level */}
          {/* <ul>
            <li>
              <Link  to="#">Column map</Link> 
            </li>
            <li>
              <Link  to="listing2.html">Column map 2</Link> 
            </li>
            <li>
              <Link  to="listing3.html">Fullwidth Map</Link> 
            </li>
            <li>
              <Link  to="listing4.html">Fullwidth Map 2</Link> 
            </li>
            <li>
              <Link  to="listing5.html">Without Map</Link> 
            </li>
            <li>
              <Link  to="listing6.html">Without Map 2</Link> 
            </li>
            <li>
              <Link  to="#">
                Single <i className="fa fa-caret-down" />
              </Link> 
              {/*third  level  */}
              {/* <ul>
                <li>
                  <Link  to="#">Style 1</Link> 
                </li>
                <li>
                  <Link  to="listing-single2.html">Style 2</Link> 
                </li>
                <li>
                  <Link  to="listing-single3.html">Style 3</Link> 
                </li>
                <li>
                  <Link  to="listing-single4.html">Style 4</Link> 
                </li>
              </ul> */}
              {/*third  level end*/}
            {/* </li> */}
          {/* </ul>  */}
          {/*second level end*/}
        </li>
        {/* <li>
          <Link  to="#">News</Link> 
        </li> */}
        <li>
          {/* <Link  to="#"> */}
            {/* Pages  */}
            {/* <i className="fa fa-caret-down" /> */}
          {/* </Link>  */}
          {/*second level */}
          {/* <ul>
            <li>
              <Link  to="#">
                Shop
                <i className="fa fa-caret-down" />
              </Link> 
              {/*third  level  */}
              {/* <ul>
                <li>
                  <Link  to="shop.html">Products</Link> 
                </li>
                <li>
                  <Link  to="product-single.html">Product single</Link> 
                </li>
                <li>
                  <Link  to="cart.html">Cart</Link> 
                </li>
              </ul> */}
              {/*third  level end*/}
            {/* </li> */}
            {/* <li>
              <Link  to="about.html">About</Link> 
            </li>
            <li>
              <Link  to="contacts.html">Contacts</Link> 
            </li>
            <li>
              <Link  to="author-single.html">User single</Link> 
            </li>
            <li>
              <Link  to="help.html">How it Works</Link> 
            </li>
            <li>
              <Link  to="booking.html">Booking</Link> 
            </li>
            <li>
              <Link  to="pricing-tables.html">Pricing</Link> 
            </li>
            <li>
              <Link  to="dashboard.html">User Dasboard</Link> 
            </li>
            <li>
              <Link  to="blog-single.html">Blog Single</Link> 
            </li>
            <li>
              <Link  to="dashboard-add-#">Add Listing</Link> 
            </li>
            <li>
              <Link  to="invoice.html">Invoice</Link> 
            </li>
            <li>
              <Link  to="login-sign.html">login Sign</Link> 
            </li>
            <li>
              <Link  to="404.html">404</Link> 
            </li> */}
          {/* </ul>  */}
          {/*second level end*/}
        </li>
      </ul>
    </nav>
  </div>
  {/* navigation  end */}
  {/* header-search_container */}
  <div className="header-search_container header-search vis-search">
    <div className="container small-container">
      <div className="header-search-input-wrap fl-wrap">
        {/* header-search-input */}
        <div className="header-search-input">
          <label>
            <i className="fal fa-keyboard" />
          </label>
          <input
            type="text"
            placeholder="What are you looking for ?"
            defaultValue=""
          />
        </div>
        {/* header-search-input end */}
        {/* header-search-input */}
        <div className="header-search-input location autocomplete-container">
          <label>
            <i className="fal fa-map-marker" />
          </label>
          <input
            type="text"
            placeholder="Location..."
            className="autocomplete-input"
            id="autocompleteid2"
            defaultValue=""
          />
          <Link  to="#">
            <i className="fal fa-dot-circle" />
          </Link> 
        </div>
        {/* header-search-input end */}
        {/* header-search-input */}
        <div className="header-search-input header-search_selectinpt ">
          <select
            data-placeholder="Category"
            className="chosen-select no-radius"
          >
            <option>All Categories</option>
            <option>All Categories</option>
            <option>Shops</option>
            <option>Hotels</option>
            <option>Restaurants</option>
            <option>Fitness</option>
            <option>Events</option>
          </select>
        </div>
        {/* header-search-input end */}
        <button
          className="header-search-button green-bg"
          onclick="window.location.to='#'"
        >
          <i className="far fa-search" /> Search{" "}
        </button>
      </div>
      <div className="header-search_close color-bg">
        <i className="fal fa-long-arrow-up" />
      </div>
    </div>
  </div>
  {/* header-search_container  end */}
  {/* wishlist-wrap*/}
  <div className="header-modal novis_wishlist">
    {/* header-modal-container*/}
    <div
      className="header-modal-container scrollbar-inner fl-wrap"
      data-simplebar=""
    >
      {/*widget-posts*/}
      <div className="widget-posts  fl-wrap">
        <ul className="no-list-style">
          <li>
            <div className="widget-posts-img">
              <Link  to="#">
                <img src="images/gallery/thumbnail/1.png" alt="" />
              </Link> 
            </div>
            <div className="widget-posts-descr">
              <h4>
                <Link  to="#">Iconic Cafe</Link> 
              </h4>
              <div className="geodir-category-location fl-wrap">
                <Link  to="#">
                  <i className="fas fa-map-marker-alt" /> 40 Journal Square
                  Plaza, NJ, USA
                </Link> 
              </div>
              <div className="widget-posts-descr-link">
                <Link  to="#">Restaurants </Link> {" "}
                <Link  to="#">Cafe</Link> 
              </div>
              <div className="widget-posts-descr-score">4.1</div>
              <div className="clear-wishlist">
                <i className="fal fa-times-circle" />
              </div>
            </div>
          </li>
          <li>
            <div className="widget-posts-img">
              <Link  to="#">
                <img src="images/gallery/thumbnail/2.png" alt="" />
              </Link> 
            </div>
            <div className="widget-posts-descr">
              <h4>
                <Link  to="#">MontePlaza Hotel</Link> 
              </h4>
              <div className="geodir-category-location fl-wrap">
                <Link  to="#">
                  <i className="fas fa-map-marker-alt" /> 70 Bright St New York,
                  USA{" "}
                </Link> 
              </div>
              <div className="widget-posts-descr-link">
                <Link  to="#">Hotels </Link> 
              </div>
              <div className="widget-posts-descr-score">5.0</div>
              <div className="clear-wishlist">
                <i className="fal fa-times-circle" />
              </div>
            </div>
          </li>
          <li>
            <div className="widget-posts-img">
              <Link  to="#">
                <img src="images/gallery/thumbnail/3.png" alt="" />
              </Link> 
            </div>
            <div className="widget-posts-descr">
              <h4>
                <Link  to="#">Rocko Band in Marquee Club</Link> 
              </h4>
              <div className="geodir-category-location fl-wrap">
                <Link  to="#">
                  <i className="fas fa-map-marker-alt" />
                  75 Prince St, NY, USA
                </Link> 
              </div>
              <div className="widget-posts-descr-link">
                <Link  to="#">Events</Link> {" "}
              </div>
              <div className="widget-posts-descr-score">4.2</div>
              <div className="clear-wishlist">
                <i className="fal fa-times-circle" />
              </div>
            </div>
          </li>
          <li>
            <div className="widget-posts-img">
              <Link  to="#">
                <img src="images/gallery/thumbnail/4.png" alt="" />
              </Link> 
            </div>
            <div className="widget-posts-descr">
              <h4>
                <Link  to="#">Premium Fitness Gym</Link> 
              </h4>
              <div className="geodir-category-location fl-wrap">
                <Link  to="#">
                  <i className="fas fa-map-marker-alt" /> W 85th St, New York,
                  USA
                </Link> 
              </div>
              <div className="widget-posts-descr-link">
                <Link  to="#">Fitness</Link> {" "}
                <Link  to="#">Gym</Link> {" "}
              </div>
              <div className="widget-posts-descr-score">5.0</div>
              <div className="clear-wishlist">
                <i className="fal fa-times-circle" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      {/* widget-posts end*/}
    </div>
    {/* header-modal-container end*/}
    <div className="header-modal-top fl-wrap">
      <h4>
        Your Wishlist :{" "}
        <span>
          <strong /> Locations
        </span>
      </h4>
      <div className="close-header-modal">
        <i className="far fa-times" />
      </div>
    </div>
  </div>
  { modalShow ?
  <>
  
  <SignIn setModalShow={setModalShow} />
  <div style={{position:"relative",zIndex:"9" , backgroundColor:"black"}}></div>
  </> 
    :null
  }
  {/*wishlist-wrap end */}
</header>
    </>

  )
}

export default Header