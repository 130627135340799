import React, { useState } from "react";
import Banner from "./Banner";
import Bannerone from "./Bannerone";
import Bannertwo from "./Bannertwo";
import Bannerthree from "./Bannerthree";
import Bannerfour from "./Bannerfour";
import { Link } from "react-router-dom";

function HeroSection() {
  const [activeTab, setActiveTab] = useState("tab-inpt1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div id="wrapper">
      {/* content*/}
      <div className="content">
        {/*section  */}
        <section className="hero-section" data-scrollax-parent="true">
          <div className="bg-tabs-wrap">
            <div
              className="bg-parallax-wrap"
              data-scrollax="properties: { translateY: '200px' }"
            >
              <div
                className="bg bg_tabs"
                style={{ backgroundImage: "url('images/bg/hero/1.jpg')" }}
              />
              <div className="overlay op7" />
            </div>
          </div>
          <div className="container small-container">
            <div className="intro-item fl-wrap">
              <span className="section-separator" />
              <div className="bubbles">
                <h1>Explore Best Places In City</h1>
              </div>
              <h3>
                Find some of the best tips from around the city from our
                partners and friends.
              </h3>
            </div>
            {/* main-search-input-tabs*/}
            <div className="main-search-input-tabs  tabs-act fl-wrap">
              <ul className="tabs-menu change_bg no-list-style">
                <li className={activeTab === "tab-inpt1" ? "current" : ""}>
                  <Link 
                    to="#tab-inpt1"
                    data-bgtab="images/bg/hero/1.jpg"
                    onClick={() => handleTabClick("tab-inpt1")}
                  >
                    Places
                  </Link> 
                </li>
                <li className={activeTab === "tab-inpt2" ? "current" : ""}>
                  <Link 
                    to="#tab-inpt2"
                    data-bgtab="images/bg/hero/2.jpg"
                    onClick={() => handleTabClick("tab-inpt2")}
                  >
                    Events
                  </Link> 
                </li>
                <li className={activeTab === "tab-inpt3" ? "current" : ""}>
                  <Link 
                    to="#tab-inpt3"
                    data-bgtab="images/bg/hero/3.jpg"
                    onClick={() => handleTabClick("tab-inpt3")}
                  >
                    Restaurants
                  </Link> 
                </li>
                <li className={activeTab === "tab-inpt4" ? "current" : ""}>
                  <Link 
                    to="#tab-inpt4"
                    data-bgtab="images/bg/hero/4.jpg"
                    onClick={() => handleTabClick("tab-inpt4")}
                  >
                    Hotels
                  </Link> 
                </li>
              </ul>
              {/*tabs */}
              <div className="tabs-container fl-wrap">
                {/*tab */}
                <div
                  className={`tab ${
                    activeTab === "tab-inpt1" ? "current" : ""
                  }`}
                >
                  <div id="tab-inpt1" className="tab-content first-tab">
                    <div className="main-search-input-wrap fl-wrap">
                      <div className="main-search-input fl-wrap">
                        <div className="main-search-input-item">
                          <label>
                            <i className="fal fa-keyboard" />
                          </label>
                          <input
                            type="text"
                            placeholder="What are you looking for?"
                            defaultValue=""
                          />
                        </div>
                        <div className="main-search-input-item location autocomplete-container">
                          <label>
                            <i className="fal fa-map-marker-check" />
                          </label>
                          <input
                            type="text"
                            placeholder="Location"
                            className="autocomplete-input"
                            id="autocompleteid"
                            defaultValue=""
                          />
                          <Link  to="#">
                            <i className="fa fa-dot-circle-o" />
                          </Link> 
                        </div>
                        <div className="main-search-input-item">
                          <select
                            data-placeholder="All Categories"
                            className="chosen-select"
                            style={{ display: "none" }}
                          >
                            <option>All Categories</option>
                            <option>Shops</option>
                            <option>Hotels</option>
                            <option>Restaurants</option>
                            <option>Fitness</option>
                            <option>Events</option>
                          </select>
                          <div
                            className="nice-select chosen-select"
                            tabIndex={0}
                          >
                            <span className="current">All Categories</span>
                            <div className="nice-select-search-box">
                              <input
                                type="text"
                                className="nice-select-search"
                                placeholder="Search..."
                              />
                            </div>
                            <ul className="list">
                              <li
                                data-value="All Categories"
                                className="option selected"
                              >
                                All Categories
                              </li>
                              <li data-value="Shops" className="option">
                                Shops
                              </li>
                              <li data-value="Hotels" className="option">
                                Hotels
                              </li>
                              <li data-value="Restaurants" className="option">
                                Restaurants
                              </li>
                              <li data-value="Fitness" className="option">
                                Fitness
                              </li>
                              <li data-value="Events" className="option">
                                Events
                              </li>
                            </ul>
                          </div>
                        </div>

                        <button
                          className="main-search-button color2-bg"
                          onclick="window.location.to='#'"
                        >
                          Search <i className="far fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*tab */}
                <div
                  className={`tab ${
                    activeTab === "tab-inpt2" ? "current" : ""
                  }`}
                >
                  <div id="tab-inpt2" className="tab-content">
                    {/* Content for tab 2 */}
                  </div>
                </div>
                {/*tab */}
                <div
                  className={`tab ${
                    activeTab === "tab-inpt3" ? "current" : ""
                  }`}
                >
                  <div id="tab-inpt3" className="tab-content">
                    {/* Content for tab 3 */}
                  </div>
                </div>
                {/*tab */}
                <div
                  className={`tab ${
                    activeTab === "tab-inpt4" ? "current" : ""
                  }`}
                >
                  <div id="tab-inpt4" className="tab-content">
                    {/* Content for tab 4 */}
                  </div>
                </div>
              </div>
              {/*tabs end*/}
            </div>
            {/* main-search-input-tabs end*/}
            <div className="hero-categories fl-wrap">
              <h4 className="hero-categories_title">
                Just looking around ? Use quick search by category :
              </h4>
              <ul className="no-list-style">
                <li>
                  <Link  to="#">
                    <i className="far fa-cheeseburger" />
                    <span>Restaurants</span>
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="far fa-bed" />
                    <span>Hotels</span>
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="far fa-shopping-bag" />
                    <span>Shops</span>
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="far fa-dumbbell" />
                    <span>Fitness</span>
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="far fa-cocktail" />
                    <span>Events</span>
                  </Link> 
                </li>
              </ul>
            </div>
          </div>
          <div className="header-sec-link">
            <Link  to="#sec1" className="custom-scroll-link">
              <i className="fal fa-angle-double-down" />
            </Link> 
          </div>
        </section>
      </div>
      <Banner />
      <Bannerone />
      <Bannertwo />
      <Bannerthree />
      <Bannerfour />
    </div>
  );
}

export default HeroSection;
